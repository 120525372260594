<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Title-->
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">{{ $t('BRANCHES.TITLE') }}</h5>
      <!--end::Title-->
      <!--begin::Separator-->
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200 d-none d-sm-block" />
      <!--end::Separator-->
      <!--begin::Search Form-->
      <div id="kt_subheader_search" class="d-flex align-items-center">
        <span v-if="pagination.totalCount" id="kt_subheader_total"
              class="text-dark-50 font-weight-bold mr-5"
        >{{ pagination.totalCount }} {{ $t('BASE.TOTAL') }}</span>
        <form @submit.stop.prevent="">
          <b-input-group class="input-group input-group-sm input-group-solid" style="max-width: 175px">
            <InputForm
              id="kt_subheader_search_form"
              v-model="query.search"
              :placeholder="$t('BASE.SEARCH')"
              debounce="300"
              trim
              type="text"
            />
            <b-input-group-append>
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </b-input-group-append>
          </b-input-group>
        </form>
      </div>
      <!--end::Search Form-->
    </div>
  </template>
  <template #toolbar>
    <div v-permission="['edit_branches']" class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyBranchesCreate' }" class="btn btn-light-success font-weight-bold btn-sm">
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
        </span>
        {{ $t('BRANCHES.NEW') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <Table v-cloak :busy.sync="$loading" :fields="fields" :items="pagination.items">
      <template #cell(name)="data">
        <div style="min-width: 150px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.name }}</span>
        </div>
      </template>
      <template #cell(actions)="data">
        <div style="min-width: 80px">
          <router-link v-permission="['edit_branches']"
                       :to="{ name: 'companyBranchesEdit', params: { id: data.item.uuid }}"
                       class="btn btn-icon btn-light-info btn-sm mr-3"
          >
            <span class="svg-icon btn-light-primary">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </router-link>
          <div v-permission="['edit_branches']" class="btn btn-icon btn-light-danger btn-sm"
               @click="remove(data.item)"
          >
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/Home/Trash.svg" />
            </span>
          </div>
        </div>
      </template>
    </Table>
    <div class="mt-3">
      <pager v-model="query.pagination.page" :pagination="pagination" />
    </div>
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/branchesRepository'
import InputForm from '@/components/forms-items/input'
import Table from '@/components/UI/Table'
import Pager from '@/components/UI/Pager'
import KTCard from '@/view/content/Card'
import Pagination from '@/model/pagination'

export default {
  name: 'CompanyBranchesIndex',
  components: {
    KTCard,
    InputForm,
    Pager,
    Table, 
  },
  data() {
    return {
      pagination: new Pagination(),
      query: {
        search: null,
        pagination: {
          page: 1,
        },
      },
      fields: [
        {
          key: 'name',
          label: this.$t('BRANCHES.NAME'),
          sortable: false,
        },
        {
          key: 'address',
          label: this.$t('FORM.ADDRESS'),
          sortable: false,
        },
        {
          key: 'scheduleStartTime',
          label: this.$t('FORM.WORK_TIME_START'),
          sortable: false,
          formatter: (v) => (v ? v.substring(0, 5) : '---'),
        },
        {
          key: 'scheduleEndTime',
          label: this.$t('FORM.WORK_TIME_END'),
          sortable: false,
          formatter: (v) => (v ? v.substring(0, 5) : '---'),
        },
        {
          key: 'actions',
          label: this.$t('BASE.ACTIONS'),
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGE.BRANCHES') }])
  },
  methods: {
    load() {
      this.loadingOn()
      Repo.paginate(this.query)
        .then((pagination) => this.pagination = pagination)
        .catch(console.log)
        .finally(this.loadingOff)
    },
    remove(ent) {
      this.$confirmAction()
        .then(this.loadingOn)
        .then(() => {
          Repo.remove(ent)
            .then(this.$toastSuccess('TOAST.CREATED'))
            .then(this.load)
            .catch(this.loadingOff)
        })
    },
  },
}
</script>

<style scoped>

</style>
